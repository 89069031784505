/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          this.searchPlaceholder();
          this.fixAccountPage();

          $(".collapse .menu-item-has-children").hover(function() {
              $(this).children('.sub-menu').show();
                  }, function() {
              $(this).children('.sub-menu').hide();
                  });


          $(".button").attr('class', 'button');
          $(".wpuf-submit").removeClass();



          $(".navmenu.navmenu-fixed-right .search").html('<form action="' + location.protocol + '//' + location.host + '/leden' + '" method="POST" accept-charset="UTF-8">' +
              '<input type="text" placeholder=" een developer" id="top-search" name="searchvalue"> ' +
              '</form> ' +
              '</div>');
          var searchright = $(".navmenu.navmenu-fixed-right .search").html();
          $(".navmenu.navmenu-fixed-right .menu-right-container .navbar-right").append('<li class="menu-item menu-item-type-custom menu-item-object-custom">'+ searchright +'</li>');
          $(".navmenu.navmenu-fixed-right .search").html('');
          $(".collapse.navbar-collapse .search").html('<div class="menu-search">' +
              '<form action="' + location.protocol + '//' + location.host + '/leden" method="POST">' +
              '<input type="text" placeholder="developer" id="top-search" name="searchvalue">' +
              '<button type="submit" id="search_submit"><span class="glyphicon glyphicon-search"></span></button>' +
              '</form>' +
              '</div>');

          $('.menu-search button[type="submit"]').prop('disabled', true);
          $('.menu-search input[type="text"]').keyup(function() {
              if($(this).val() != '') {
                  $('.menu-search button[type="submit"]').prop('disabled', false);
              }
          });

          $(".menu-add-post a").attr('href', 'http://' + window.location.hostname + '/user/?profiletab=add-post');
          $(".menu-edit-profile a").attr('href', 'http://' + window.location.hostname + '/user/?profiletab=main&um_action=edit');
          var data = [];
          var sentences = [];
          $(".searchroles li").each(function (index) {
              data.push({label: $(this).text(), category: 'Specialisme'});
              sentences.push($(this).text());
          });
          $(".searchpeople li").each(function (index) {
              data.push({label: $(this).text(), category: 'Personen'});
              sentences.push($(this).text());
          });

          $('[data-toggle="tab"]').click(function() {
              $(this).children('li').addClass('active');
              $(this).siblings('a').children('li').removeClass('active');
          });

          if ( $( ".navmenu-fixed-right #top-search" ).length ) {

            superplaceholder({

                el: document.querySelector('.navmenu-fixed-right #top-search'),
                sentences: sentences,
                options: {
                    // delay between letters (in milliseconds)
                    letterDelay: 100, // milliseconds
                    // delay between sentences (in milliseconds)
                    sentenceDelay: 1000,
                    // should start on input focus. Set false to autostart
                    startOnFocus: false,
                    // loop through passed sentences
                    loop: true,
                    // Initially shuffle the passed sentences
                    shuffle: false,
                    // Show cursor or not. Shows by default
                    showCursor: true,
                    // String to show as cursor
                    cursor: '|'
                }
            });
          }

		  if ( $( "navbar-collapse #top-search" ).length ) {
            superplaceholder({

                el: document.querySelector('.navbar-collapse #top-search'),
                sentences: sentences,
                options: {
                    // delay between letters (in milliseconds)
                    letterDelay: 100, // milliseconds
                    // delay between sentences (in milliseconds)
                    sentenceDelay: 1000,
                    // should start on input focus. Set false to autostart
                    startOnFocus: false,
                    // loop through passed sentences
                    loop: true,
                    // Initially shuffle the passed sentences
                    shuffle: false,
                    // Show cursor or not. Shows by default
                    showCursor: true,
                    // String to show as cursor
                    cursor: '|'
                }
            });
		  }

          $.widget( "custom.catcomplete", $.ui.autocomplete, {
              _create: function() {
                  this._super();
                  this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
              },
              _renderMenu: function( ul, items ) {
                  var that = this,
                      currentCategory = "";
                  $.each( items, function( index, item ) {
                      var li;
                      if ( item.category != currentCategory ) {
                          ul.append( "<li class='ui-autocomplete-category'><b>" + item.category + "</b></li>" );
                          currentCategory = item.category;
                      }
                      li = that._renderItemData( ul, item );
                      if ( item.category ) {
                          li.attr( "aria-label", item.category + " : " + item.label );
                      }
                  });
              }
          });

          $.ui.autocomplete.prototype._renderItem = function (ul, item) {
              item.label = item.label.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(this.term) + ")(?![^<>]*>)(?![^&;]+;)", "gi"), "<strong class='searchhighlight'>$1</strong>");
              return $("<li></li>")
                  .data("item.autocomplete", item)
                  .append("<a>" + item.label + "</a>")
                  .appendTo(ul);
          };


            $(".home-search #search-um").catcomplete({
                delay: 0,
                source: data,
                select: function (event, ui) {
                    $('.home-search #search-um').val(ui.item.value);
                    $(".searchform").submit();
                }
            });

             $(".navbar-collapse #top-search").catcomplete({
                 delay: 0,
                 source: data,
                 select: function (event, ui) {
                     $('.navbar-collapse #top-search').val(ui.item.value);
                     $(".navbar-collapse #top-search").parent().submit();
                 }
             }).catcomplete("widget").css({"z-index": "99999", "position": "fixed"}).addClass('autocomplete-top');

          $(".navmenu-fixed-right #top-search").catcomplete({
              delay: 0,
              source: data,
              select: function (event, ui) {
                  $('.navmenu-fixed-right #top-search').val(ui.item.value);
                  $(".navmenu-fixed-right #top-search").parent().submit();
              }
          }).catcomplete("widget").css({"z-index": "99999", "position": "fixed"});



          $(".searchitem").click(function () {
              $('#qnimate').addClass('popup-box-on');
          });

          $("#removeClass").click(function () {
              $('#qnimate').removeClass('popup-box-on');
          });

          $("input[name=searchtype]:radio").change(function () {
              $(".textsearch").attr('name',$(this)[0].value);

          });



          if($(".insearch")[0] && $(".um-members-none")[0]) {
              $(".um-directory").append('Het spijt ons. We kunnen geen gebruikers vinden die je zoekopdracht overeenkomen.');
          }
          // Setup drop down menu
          $('.dropdown-toggle').dropdown();

          // Fix input element click problem
          $('.dropdown-menu input, .dropdown-menu label, .dropdown-menu select').click(function(e) {
              e.stopPropagation();
          });

          $(".comment-list li").each(function (index) {
              isFirstTime = true;
              $(this).first().each(function (index2) {
                  if (isFirstTime) {
                      commentdate = $(this).context.children[0].children[0].children[1];
                      commentdatetxt = $(commentdate).text().trim();
                      $(commentdate).html("<p>" + commentdatetxt +"</p>");
                      isFirstTime = false;
                  }
              });
          });
          var viewstext = $(".single .views .viewnumber").text();
          viewstext = viewstext.replace('views', '');
          $(".single .views .viewnumber").text(viewstext);

        $('.owl-carousel').owlCarousel({
          loop:true,
          margin:1,
          nav:false,
            autoPlay: 5000,

          responsive:{
            0:{
              items:1
            },
            992:{
              items:2
            },

          }
        });

          $('#signup-introduces-form-close').click(function() {
	          $('.signup-introduces-form-wrapper').fadeOut();
          });

	      $('#signup-members-form-close').click(function() {
		      $('.signup-members-form-wrapper').fadeOut();
	      });

          $('.signup-button-introduces').click(function() {
              $('.signup-introduces-form-wrapper').fadeIn();
          });

	      $('.signup-button-members').click(function() {
		      $('.signup-members-form-wrapper').fadeIn();
	      });

          // Custom
          var stickyToggle = function(sticky, stickyWrapper, scrollElement) {
            var stickyHeight = sticky.outerHeight();
            var stickyTop = stickyWrapper.offset().top;
            if (scrollElement.scrollTop() >= stickyTop){
              stickyWrapper.height(stickyHeight);
              sticky.addClass("is-sticky");
              $(".menu-brand").removeClass('hidden');
            }
            else{

              sticky.removeClass("is-sticky");
              stickyWrapper.height('auto');
              $(".menu-brand").addClass('hidden');
            }
          };

          // Find all data-toggle="sticky-onscroll" elements
          $('[data-toggle="sticky-onscroll"]').each(function() {
            var sticky = $(this);
            var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
            sticky.before(stickyWrapper);
            sticky.addClass('sticky');

            // Scroll & resize events
            $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
              stickyToggle(sticky, stickyWrapper, $(this));
            });

            // On page load
            stickyToggle(sticky, stickyWrapper, $(window));
          });

          var data = [];
          var sentences = [];
          $(".searchroles li").each(function (index) {
              data.push({label: $(this).text(), category: 'SPECIALISME'});
              sentences.push($(this).text());
          });
          $(".searchpeople li").each(function (index) {
              data.push({label: $(this).text(), category: 'Personen'});
              sentences.push($(this).text());
          });

		if ( $( ".home-search #search-um" ).length ) {
		  superplaceholder({
			el: document.querySelector('.home-search #search-um'),
			sentences: sentences,
			options: {
			  // delay between letters (in milliseconds)
			  letterDelay: 100, // milliseconds
			  // delay between sentences (in milliseconds)
			  sentenceDelay: 1000,
			  // should start on input focus. Set false to autostart
			  startOnFocus: false,
			  // loop through passed sentences
			  loop: true,
			  // Initially shuffle the passed sentences
			  shuffle: false,
			  // Show cursor or not. Shows by default
			  showCursor: true,
			  // String to show as cursor
			  cursor: '|'
			}
		  });
		}

      },
        searchPlaceholder: function() {
            var data = [];
            var sentences = [];
            $(".searchroles li").each(function (index) {
                data.push({label: $(this).text(), category: 'SPECIALISME'});
                sentences.push($(this).text());
            });
            $(".searchpeople li").each(function (index) {
                data.push({label: $(this).text(), category: 'Personen'});
                sentences.push($(this).text());
            });
        },

      fixAccountPage: function () {
        $( '#current_user_password, #user_password, #confirm_user_password' ).on('keyup', function (  ) {
          if(($('#current_user_password').val().length > 0) && ($('#user_password').val().length > 0) && ($('#confirm_user_password').val().length > 0)){
            $('.um-account-tab-password #um_account_submit').show();
          }
          else {
            $('.um-account-tab-password #um_account_submit').hide();
          }
        });
      },
      
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

      'register': {
          init: function() {
              var roles = [];
              var role = '';
              $(".um-field-role_select").hide();
              $("#werk").html(' ');
              $("select[data-key='role_select'] option").each(function () {
                  role = $(this).text();
                  if(role.toLowerCase() != ('member').toLowerCase() && role.toLowerCase() != ('admin').toLowerCase()) {
                      $("#werk").append('<option value="' + role + '">' + role + '</option>');
                  }
              });
              // JavaScript to be fired on the about us page
          }
      },
      'user': {
          init: function() {
                $(".um-field-role_select").hide();
              $("#werk").html(' ');
              var role = '';
              $("select[data-key='role_select'] option").each(function () {
                  role = $(this).text();
                  if(role.toLowerCase() != ('member').toLowerCase() && role.toLowerCase() != ('admin').toLowerCase()) {
                      $("#werk").append('<option value="' + role + '">' + role + '</option>');
                  }
              });
              $(".um-profile-nav-item.um-profile-nav-add-post .title").text("Oogst schrijven");
              $(".um-profile-nav-item.um-profile-nav-add-post a").attr("title","Oogst schrijven");
              function getParameterByName(name, url) {
                  if (!url) {
                      url = window.location.href;
                  }
                  name = name.replace(/[\[\]]/g, "\\$&");
                  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                      results = regex.exec(url);
                  if (!results) { return null; }
                  if (!results[2]) { return ''; }
                  return decodeURIComponent(results[2].replace(/\+/g, " "));
              }
              var editlink = '';
              var postid = '';
              $(".my-posts .um-item").each(function (index) {
                  $(this).find('.um-item-meta span').first().hide();
                  editlink = $(this).find(".um-item-meta span:nth-child(2) a:nth-child(1) ").attr('href');
                  postid = getParameterByName('pid', editlink);
                  $(this).find(".um-item-meta span:nth-child(2) a:nth-child(2) ").attr('href', '?profiletab=my-posts&deletepost=' + postid + '&_wpnonce=fc07160873');
              });

              var post_count = $(".post_count span").text();
              $(".post_count").text('Je hebt ' + post_count + ' oogsten geschreven');


              $(".um-profile-nav-posts.um-profile-custom-nav-item span").text('Oogsten');
              $(".um-profile-nav-posts.um-profile-custom-nav-item a").attr('title', 'Oogsten');
              var user_page_id = $(".um-profile-photo").attr('data-user_id');
              var curuserid = script_vars.user_id;
              if (user_page_id == curuserid) {
                  var newurl = $('.um-profile-nav-item.um-profile-nav-my-posts a').attr('href');
                  $(".um-profile-nav-item.um-profile-nav-posts a").attr('href', newurl);
                  var isactive = $('.um-profile-nav-item.um-profile-nav-my-posts').hasClass('active');
                  $('.um-profile-nav-item.um-profile-nav-my-posts').hide();
                  if (isactive) {
                      $(".um-profile-nav-item.um-profile-nav-posts").attr('class', 'um-profile-nav-item um-profile-nav-my-posts active');
                  } else {
                      $(".um-profile-nav-item.um-profile-nav-posts").attr('class', 'um-profile-nav-item um-profile-nav-my-posts');
                  }
              }
              $(".um-profile-nav-item").removeClass('um-profile-nav-item').addClass('um-profile-custom-nav-item');

              var profiletab = getParameterByName('profiletab');
              switch (profiletab) {
                  case 'posts':
                      $(".posts.posts-default .um-item").each(function () {
                          var postimg = $(this).find(".um-item-img img").attr('src');
                          var posttitle = $(this).find(".um-item-link a").text();
                          var postlink = $(this).find(".um-item-link a").attr('href');
                          var posttime = $(this).find(".um-item-meta span:nth-child(1)").text();
                          var authorname = $(".um-profile-meta .um-name").text();
                          posttime = posttime.replace('geweest', 'geleden');
                          var postcommentsnum = $(this).find(".um-item-meta span:nth-child(3)").text();
                          postcommentsnum = parseInt(postcommentsnum, 10);
                          if(postcommentsnum !== parseInt(postcommentsnum, 10)) {
                              postcommentsnum = 0;
                          }

                         $(this).html('<a href="' + postlink +'"><div class="col-md-12 header-row">' +
                             '<div class="header-row-image-wrapper">' +
                              '<div class="header-row-image" style="background-image: url(\''+ postimg +'\');"></div>' +
                              '</div>' +
                              '<div class="header-row-wrapper">' +
                              '<div class="header-row-details">' +
                              '<h1 class="user">'+ authorname +':</h1>' +
                              '<h1 class="entry-title">'+ posttitle +'</h1>' +
                             '<div class="time">' +
                              '<time class="updated"><span class="fa fa-clock-o"></span> '+ posttime +' </time>' +
                             '</div>' +
                             '<div class="comments"><span class="fa fa-comment-o"></span> ' + postcommentsnum +' </div>'+
                              '</div>' +
                              '</div>' +
                              '</div></a>');
                      });
                      break;
                  case 'my-posts':
                      $(".um-profile-body.my-posts.my-posts-default .um-item").each(function () {
                          var postimg = $(this).find(".um-item-thumbnail-special img").attr('src');
                          var posttitle = $(this).find(".um-titles .um-item-link a").text();
                          var postlink = $(this).find(".um-titles .um-item-link a").attr('href');
                          var authorname = $(".um-profile-meta .um-name").text();
                          var editlink = $(this).find(".um-item-meta span:nth-child(2) a:nth-child(1)").attr('href');
                          var deletelink = $(this).find(".um-item-meta span:nth-child(2) a:nth-child(2)").attr('href');
                          if(postlink != 'undefined' && posttitle.length != 0 ) {
                              $(this).html('<div class="col-md-12 header-row">' +
                                  '<div class="header-row-image-wrapper">' +
                                  '<div class="header-row-image" style="background-image: url(\'' + postimg + '\');"></div>' +
                                  '</div>' +
                                  '<div class="header-row-wrapper">' +
                                  '<div class="header-row-details">' +
                                  '<h1 class="user">' + authorname + ':</h1>' +
                                  '<h1 class="entry-title">' + posttitle + '</h1>' +
                                  '<a href="' + editlink + '"><div class="time">' +
                                  '<time class="updated"><span class="fa fa-pencil"></span> Edit </time>' +
                                  '</div></a>' +
                                  '<a href="' + deletelink + '" onclick="return confirm(\'Are you sure to delete this post?\');"><div class="comments"><span class="fa fa-trash"></span> Delete </div></a>' +
                                  '</div>' +
                                  '</div>' +
                                  '</div>');
                              $(this).find('.col-md-12.header-row').wrap("<a href='" + postlink + "' ></a>");
                          } else { $(this).hide(); }
                      });
                      break;
                  case 'comments' :
                      $(".um-profile-body.comments.comments-default .um-item").each(function () {

                          var commenttext = $(this).find(".um-item-link a").text();
                          var commentlink = $(this).find(".um-item-link a").attr('href');
                          var postname = $(this).find(".um-item-meta a").text();
                          var postlink = $(this).find(".um-item-meta a").attr('href');
                          var profilephoto = $(".um-header .um-profile-photo img").attr('src');
                          var profilelink = $(".um-header .um-profile-photo a").attr('href');
                          var authorname = $(".um-profile-meta .um-name").text();

                          $(this).html('<div class="comment-author vcard">' +
                              '<a href="'+ profilelink +'"><img src="'+ profilephoto +'" class="func-um_user gravatar avatar avatar-32 um-avatar um-avatar-uploaded" width="32" height="32" alt="'+ authorname +'" style="display: inline-block;">' +
                              '<b class="fn">'+ authorname +'</b></a>' +
                              '<span class="says">schreef op <a href="'+ postlink +'">'+ postname +'</a>:</span></div>' +
                              '<div class="comment-content">' +
                              '<a href="'+ commentlink +'"><p style="margin-left: 40px">'+ commenttext+'</p></a>' +
                              '</div>');
                      });
                      break;
              }
              $(".um-profile-nav-my-posts.um-profile-custom-nav-item span").text('Oogsten');
              $(".um-profile-nav-my-posts.um-profile-custom-nav-item a").attr('title', 'Oogsten');
              $(".um-profile-nav-posts.um-profile-custom-nav-item span").text('Oogsten');
              $(".um-profile-nav-posts.um-profile-custom-nav-item a").attr('title', 'Oogsten');
              var postcount = parseInt(($(".um-profile-body.my-posts.my-posts-default .post_count").text()).replace(/[^0-9\.]/g, ''), 10);
              if (postcount == 0) {
                  $(".um-profile-body.my-posts.my-posts-default").text('Geen oogsten gevonden');
              }
              var profilenote = $(".um-profile-note span").text();
              profilenote = profilenote.replace('berichten', 'oogsten');
              $(".um-profile-note span").text(profilenote);
              var loginasusertext = '';
              $(".um-dropdown-b ul li").each(function (index) {
                  loginasusertext = $(this).find('a').text();
                  loginasusertext = loginasusertext.replace('as', 'als');
                  loginasusertext = loginasusertext.replace('this', 'deze');
                  loginasusertext = loginasusertext.replace('user', 'gebruiker');
                  $(this).find('a').text(loginasusertext);
              });

              $('.um-googlemap').click(function () {
                  $('.um-googlemap iframe').css("pointer-events", "auto");
              });

              $( ".um-googlemap" ).mouseleave(function() {
                  $('.um-googlemap iframe').css("pointer-events", "none");
              });


          }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
